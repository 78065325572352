import { Audience } from 'lib-ad-platform-models'

import Api from '@/api/api'
import router from '@/router'
import { Console } from '@/utils/Console'

type NodeSearchResult = {
    id: string,
    label: string,
    name: string
}

type RelationShip = {
    regex: string,
    kind: string,
    name: string,
    label: string,
    id: string,
    type: string,
    key: string
}

type AudienceSelectSearchResult = {
    id:string,
    name: string,
    label: string,
    createdAt: number,
    type: string,
    size: number,
    nominativeGroupFlag: number,
    relationships: RelationShip[]
}

type Group = {
    audiencesCount?: number,
    id: string,
    labels: string[],
    name: string,
    updatedAt: number,
    relationships?: RelationShip[]
}

export type AudienceState = {
    audience: Audience,
    audiences: Audience[],
    searchResults: NodeSearchResult[],
    audienceSelectSearchResults: AudienceSelectSearchResult[],
    group: Group,
    groups: Group[],
}

const state: AudienceState = ({
    audience: null,
    audiences: [],
    searchResults: [],
    audienceSelectSearchResults: [],
    group: null,
    groups: [],
})

const getters = {}

const mutations = {
    addAudienceGroupRels(state: AudienceState, payload: any): void {
        if (typeof state.group.relationships === 'undefined') {
            const grp = state.group
            grp.relationships = []
            state.group = { ...grp }
        }

        state.group.relationships = [...state.group.relationships, ...[payload.target]]
    },
    addGroupDocumentsRels(state: AudienceState, documents: any): void {
        if (typeof state.group.relationships === 'undefined') {
            const grp = state.group
            grp.relationships = []
            state.group = { ...grp }
        }

        state.group.relationships = [...state.group.relationships, ...documents]
    },
    setAudienceGroupRels(state: AudienceState, payload: any): void {
        state.group = payload
    },
    resetAudienceGroupRels(state: AudienceState): void {
        state.group = null
    },
    unsetAudienceGroupRels(state: AudienceState, payload: any): void {
        let rmIndex = null
        const rels = state.group.relationships

        for (const i in rels) {
            if (rels[i].id === payload.target.id && rels[i].label === payload.target.label) {
                rmIndex = i
                break
            }
        }

        if (rmIndex !== null) {
            rels.splice(rmIndex, 1)
        }

        state.group.relationships = rels
    },
    pushAudience(state: AudienceState, payload: any): void {
        state.audiences = [...[new Audience(payload)], ...state.audiences]
    },
    setAudiences(state: AudienceState, payload: any): void {
        state.audiences = [...payload].map((c) => new Audience(c))
    },
    setAddAudiences(state: AudienceState, payload: any): void {
        state.audiences = [...state.audiences, ...payload.map((c) => new Audience(c))]
    },
    setSelectAudienceResults(state: AudienceState, payload: any): void {
        state.audienceSelectSearchResults = payload
    },
    setAudience(state: AudienceState, payload: any): void {
        if (!payload) {
            state.audience = null
        } else {
            state.audience = new Audience(payload)

            for (const i in state.audiences) {
                if (state.audiences[i].id === state.audience.id) {
                    Object.assign(state.audiences[i], {
                        name: state.audience.name,
                        type: state.audience.type,
                        area: state.audience.area,
                        author: state.audience.author,
                        isInCatalog: state.audience.isInCatalog,
                    })
                }
            }
        }
    },
    setSearchNodesResult(state: AudienceState, payload: any): void {
        const isGroupPage = router.currentRoute.name === 'AudienceGroupEditPage'
        const relationships = (state.group && isGroupPage)
            ? state.group.relationships
            : state.audience.relationships

        const sortOrder = {
            Feed: 1,
            Document: 2,
            App: 3,
        }

        state.searchResults = payload.filter(({ label, name }) => {
            let isNotInAudience = true

            if (relationships) {
                relationships.forEach((rel) => {
                    if (label === rel.label && name === rel.name) {
                        isNotInAudience = false
                    }
                })
            }

            if (label === 'Zip') {
                return isNotInAudience && new RegExp('[0-9][0-9]').test(name)
            }

            if (label === 'User' && !isGroupPage) {
                return name.endsWith('@360medics.com')
            }

            if (label === 'Role') {
                return name !== 'ROLE_PROSPECT'
            }

            return isNotInAudience
        }).sort((a, b) => {
            // if is group page, sort by Feed, then Document, then App
            // else don't sort results
            return isGroupPage
                ? (sortOrder[a.label] || 4) - (sortOrder[b.label] || 4)
                : 1
        })
    },
    pushAudienceRel(state: AudienceState, payload: any): void {
        let canAdd = true
        const aud = state.audience

        for (const i in aud.relationships) {
            const rel = aud.relationships[i]

            if (`${rel.id}` === `${payload.id}` && rel.label === payload.label) {
                canAdd = false
            }
        }

        if (canAdd === true) {
            aud.relationships.push(payload.target)
        }

        state.audience = new Audience(aud)
    },
    pushAudienceUsersRels(state: AudienceState, payload: any): void {
        const aud = state.audience

        const users = payload.filter(({ id, label }) => {
            return !aud.relationships.some((item) => item.id === id && item.label === label)
        })

        aud.relationships.push(...users)
        state.audience = new Audience(aud)
    },
    setAudienceRel(state: AudienceState, payload: any): void {
        const { audience } = state

        if (payload.source.id !== state.audience.id) {
            throw new Error('Unmatching origin and audience ID in SET_AUDIENCE_REL mutation.')
        }

        for (const i in state.audience.relationships) {
            const rel = state.audience.relationships[i]

            if (rel.id === payload.target.id && rel.label === payload.target.label) {
                audience.relationships[i].action = payload.relationship.action
                audience.relationships[i].caping = payload.relationship.caping
            }
        }

        state.audience = new Audience(audience)
    },
    unsetAudienceRel(state: AudienceState, payload: any): void {
        let delIndex = null
        const { audience } = state

        for (const i in state.audience.relationships) {
            const rel = state.audience.relationships[i]

            if (rel.id === payload.target.id && rel.label === payload.target.label) {
                delIndex = i
                break
            }
        }

        if (delIndex !== null) {
            audience.relationships.splice(delIndex, 1)
        }

        state.audience = new Audience(audience)
    },
    setGroups(state: AudienceState, payload: any): void {
        state.groups = payload
            .sort((a, b) => b.updatedAt - a.updatedAt)
            .map((group) => {
                return {
                    ...group,
                    labels: group.labels.sort(),
                }
            })
    },
    setAddGroups(state: AudienceState, payload: any): void {
        state.groups = [...state.groups, ...payload.sort((a, b) => b.updatedAt - a.updatedAt)]
    },
    removeGroup(state: AudienceState, payload: any): void {
        state.groups = state.groups.filter(({ id }) => id !== payload)
    },
}

const actions = {
    async createGroup({ commit }: any, payload: any): Promise<string> {
        const path = '/v2/group'
        Console.logAction(`Vuex store action: < createGroup > as POST ${path}`, 'post')

        try {
            const groupId = await Api.post(path, payload)
            return groupId
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
            return error
        }
    },
    async updateGroup({ commit }: any, payload: any): Promise<void> {
        const path = `/v2/group/${payload.id}`
        Console.logAction(`Vuex store action: < updateGroup > as PUT ${path}`, 'put')

        try {
            await Api.put(path, payload)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },
    async deleteGroup({ commit } :any, payload: any): Promise<string> {
        const path = `/v2/group/${payload.id}`
        Console.logAction(`Vuex store action: < deleteGroup > as DELETE ${path}`, 'delete')

        commit('xhr/isPending', true, { root: true })

        try {
            const response = await Api.delete(path, { name: payload.name })
            commit('removeGroup', payload.id)
            return response
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
            return error
        } finally {
            commit('xhr/isPending', false, { root: true })
        }
    },
    async loadGroup({ commit }: any, payload: any): Promise<void> {
        const path = `/v2/group/${payload.label}/${payload.id}`
        Console.logAction(`Vuex store action: < loadGroup > as GET ${path}`, 'get')

        try {
            const result = await Api.get(path)
            commit('setAudienceGroupRels', result)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },
    async loadGroups({ commit }: any, payload: any): Promise<void> {
        const { params } = payload
        const path = '/v2/groups'
        Console.logAction(`Vuex store action: < loadGroups > as GET ${path}`, 'get')

        if (params.skip === 0) {
            commit('xhr/isPending', true, { root: true })
        }

        try {
            const result = await Api.get(path, { ...params })
            if (params.skip === 0) {
                commit('setGroups', result)
            } else {
                commit('setAddGroups', result)
            }
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        } finally {
            commit('xhr/isPending', false, { root: true })
        }
    },
    async updateAudienceRel({ commit }: any, payload: any): Promise<void> {
        const path = `/audience/${payload.source.id}/relationships`
        Console.logAction(`Vuex store action: < updateAudienceRel > as PUT ${path}`, 'put')

        try {
            await Api.put(path, payload)
            commit('setAudienceRel', payload)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },
    async createAudience({ commit }: any, payload: any): Promise<string> {
        const path = '/audience'
        Console.logAction(`Vuex store action: < createAudience > as POST ${path}`, 'post')

        commit('xhr/isPending', true, { root: true })

        try {
            const result = await Api.post(path, payload)
            commit('pushAudience', result)
            return result.id
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
            return error
        } finally {
            commit('xhr/isPending', false, { root: true })
        }
    },
    async loadAudiences({ commit }: any, params: any): Promise<void> {
        const path = '/audiences'
        Console.logAction(`Vuex store action: < loadAudiences > as GET ${path}`, 'get')

        if (params.skip === 0) {
            commit('xhr/isPending', true, { root: true })
        }

        try {
            const result = await Api.get(path, params)
            if (params.skip === 0) {
                commit('setAudiences', result)
            } else {
                commit('setAddAudiences', result)
            }
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        } finally {
            commit('xhr/isPending', false, { root: true })
        }
    },
    async duplicateAudience({ commit }: any, id: any): Promise<Audience> {
        const path = `/audience/${id}/duplicate`
        Console.logAction(`Vuex store action: < duplicateAudience > as POST ${path}`, 'post')

        commit('xhr/isPending', true, { root: true })

        try {
            const result = await Api.post(path, id)
            commit('setAudience', result)
            commit('pushAudience', result)
            return result
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
            return error
        } finally {
            commit('xhr/isPending', false, { root: true })
        }
    },
    async duplicateGroup({ commit }: any, payload: any): Promise<string> {
        const path = `/group/${payload.id}/duplicate`
        Console.logAction(`Vuex store action: < duplicateGroup > as POST ${path}`, 'post')

        commit('xhr/isPending', true, { root: true })

        try {
            const result = await Api.post(path, payload)
            return result
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
            return error
        } finally {
            commit('xhr/isPending', false, { root: true })
        }
    },
    async selectAudienceSearch({ commit }: any, params: any): Promise<void> {
        const path = '/audiences/search'
        Console.logAction(`Vuex store action: < selectAudienceSearch > as GET ${path}`, 'get')

        commit('xhr/isPending', true, { root: true })

        try {
            const result = await Api.get(path, params)
            commit('setSelectAudienceResults', result.filter(({ type }) => type === params.type))
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        } finally {
            commit('xhr/isPending', false, { root: true })
        }
    },
    async loadAudience({ commit }: any, id: number): Promise<void> {
        const path = `/audience/${id}`
        Console.logAction(`Vuex store action: < loadAudience > as GET ${path}`, 'get')

        commit('xhr/isPending', true, { root: true })

        try {
            const result = await Api.get(path)
            commit('setAudience', result)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        } finally {
            commit('xhr/isPending', false, { root: true })
        }
    },
    async updateAudience({ commit }: any, payload: any): Promise<void> {
        const path = `/audience/${payload.id}`
        Console.logAction(`Vuex store action: < updateAudience > as PUT ${path}`, 'put')

        commit('xhr/isPending', true, { root: true })

        try {
            const result = await Api.put(path, payload)
            commit('setAudience', result)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        } finally {
            commit('xhr/isPending', false, { root: true })
        }
    },
    async searchNodes({ commit }: any, payload: any): Promise<any> {
        const path = '/search'
        Console.logAction(`Vuex store action: < searchNodes > as GET ${path}`, 'get')

        try {
            const result = await Api.get(path, payload)
            if (payload.commitResult) {
                commit('setSearchNodesResult', result)
            }
            return result
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
            return error
        }
    },
    async addAudienceRel({ commit, dispatch }: any, payload: any): Promise<void> {
        const path = '/v2/relationship'
        Console.logAction(`Vuex store action: < addAudienceRel > as POST ${path}`, 'post')

        if (!payload.source.id) {
            // @ts-ignore: next-line
            const { type, name, area } = state.audience
            payload.source.id = await dispatch('createAudience', { type, name, area })
            router.push(`/audiences/${payload.source.id}`)
        }

        try {
            const result = await Api.post(path, payload)
            commit('pushAudienceRel', result)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },
    async addAudienceGroupRel({ commit }: any, payload: any): Promise<void> {
        const path = '/v2/relationship'
        Console.logAction(`Vuex store action: < addAudienceGroupRel > as POST ${path}`, 'post')

        try {
            await Api.post(path, payload)
            commit('addAudienceGroupRels', payload)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },
    async addGroupDocumentsRel({ commit }: any, payload: any): Promise<void> {
        const path = '/v2/relationships/documents'
        Console.logAction(`Vuex store action: < addGroupDocumentsRel > as POST ${path}`, 'post')

        commit('xhr/isPending', true, { root: true })

        try {
            const result = await Api.post(path, payload)
            commit('addGroupDocumentsRels', result.documents)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        } finally {
            commit('xhr/isPending', false, { root: true })
        }
    },
    async importUsersListInAudience({ commit }: any, payload: any): Promise<string> {
        const path = `/audience/${payload.audienceId}/emails/attach`
        Console.logAction(`Vuex store action: < importUsersListInAudience > as PUT ${path}`, 'put')

        try {
            const result = await Api.put(path, payload)
            commit('pushAudienceUsersRels', result)
            return ('Import done')
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
            return error
        }
    },
    async rmAudienceGroupRel({ commit }: any, payload: any): Promise<void> {
        const path = '/delete'
        Console.logAction(`Vuex store action: < rmAudienceGroupRel > as DELETE ${path}`, 'delete')

        const data = {
            nodes: [],
            relationships: [
                {
                    source: payload.source,
                    target: payload.target,
                },
            ],
        }

        try {
            await Api.delete(path, data)
            commit('unsetAudienceGroupRels', payload)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },
    async detachAudienceRel({ commit }: any, payload: any): Promise<void> {
        const path = '/detach'
        Console.logAction(`Vuex store action: < detachAudienceRel > as DELETE ${path}`, 'delete')

        const data = {
            nodes: [],
            relationships: [
                {
                    source: payload.source,
                    target: payload.target,
                },
            ],
        }

        try {
            await Api.delete(path, data)
            commit('unsetAudienceRel', payload)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },
    async rmAudienceRel({ commit }: any, payload: any): Promise<void> {
        const path = '/delete'
        Console.logAction(`Vuex store action: < rmAudienceRel > as DELETE ${path}`, 'delete')

        const data = {
            nodes: [],
            relationships: [
                {
                    source: payload.source,
                    target: payload.target,
                },
            ],
        }

        try {
            await Api.delete(path, data)
            commit('unsetAudienceRel', payload)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },
    async rmAudience({ commit, dispatch }: any, payload: any): Promise<void> {
        const path = `/audience/${payload.id}/del`
        Console.logAction(`Vuex store action: < rmAudience > as DELETE ${path}`, 'delete')

        try {
            await Api.delete(path)
            dispatch('loadAudiences', { limit: 25, order: 'DESC', orderBy: 'createdAt', skip: 0 })
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },
    async rmSeveralAudiences({ commit, dispatch }: any, payload: any): Promise<void> {
        const path = '/audiences/del'
        Console.logAction(`Vuex store action: < rmSeveralAudiences > as DELETE ${path}`, 'delete')

        try {
            await Api.delete(path, payload)
            dispatch('loadAudiences', { limit: 25, order: 'DESC', orderBy: 'createdAt', skip: 0 })
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },
    async getAudienceSize({ commit }: any, payload: any): Promise<number> {
        const path = '/audience/size'
        Console.logAction(`Vuex store action: < getAudienceSize > as POST ${path}`, 'post')

        try {
            const result = await Api.post(path, payload)
            return result
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
            return error
        }
    },
    async searchUsersAccount({ commit }: any, payload: any): Promise<any> {
        const path = '/search/users'
        Console.logAction(`Vuex store action: < searchUsersAccount > as POST ${path}`, 'post')

        try {
            const result = await Api.post(path, payload)
            return result
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
            return error
        }
    },
    async resetUserRels({ commit }: any, payload: any): Promise<string> {
        const path = `/user/${payload.userId}/reset`
        Console.logAction(`Vuex store action: < resetUserRels > as PUT ${path}`, 'put')

        try {
            const result = await Api.put(path, payload)
            return result
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
            return error
        }
    },
}

export default {
    namespaced: true,
    mutations,
    getters,
    actions,
    state,
}
