import axios from 'axios'
import { AppSearch } from '@/models/app-search'

declare const window: any

export interface ApiError {
    [x: string]: any
}
class ApiService {
    apiUrl: string
    headers: any

    constructor() {
        this.apiUrl = process.env.VUE_APP_API_URL
    }

    private alertIfAdBlock() {
        if (window.canRunAds) {
            alert('Add block est activé. La palteforme pourrait ne pas fonctionner correctement.')
        }
    }

    get<T = any>(path: string, params = null, absUrl: string = null): Promise<T> {
        const apiUrl = (absUrl !== null) ? absUrl : this.apiUrl

        return new Promise((resolve, reject) => {
            axios
                .get<T>(`${apiUrl}${path}`, { params })
                .then((res) => {
                    this.alertIfAdBlock()
                    if (res) {
                        resolve(res.data)
                    }
                })
                .catch((error) => {
                    console.error(error)
                    reject(error)
                })
        })
    }

    searchAppBack360(name?: string): Promise<AppSearch[]> {
        return new Promise((resolve, reject) => {
            axios
                .get<AppSearch[]>(`${process.env.VUE_APP_360API_URL}/rest/app/list`, {
                    headers: {
                        Authorization: `Token ${process.env.VUE_APP_360API_TOKEN}`,
                    },
                    params: {
                        name,
                        status: 'published',
                    },
                })
                .then((res) => {
                    this.alertIfAdBlock()
                    resolve(res.data)
                })
                .catch((error) => {
                    console.error(error)
                    reject(error)
                })
        })
    }

    put(path: string, body = null): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .put(`${this.apiUrl}${path}`, body)
                .then((res) => {
                    this.alertIfAdBlock()
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    post(path: string, body = null): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .post(`${this.apiUrl}${path}`, body)
                .then((res) => {
                    this.alertIfAdBlock()
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    delete(path: string, body = null): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.delete(`${this.apiUrl}${path}`, { data: body })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    upload(path: string, data: FormData) {
        const headers = {
            'Content-Type': 'multipart/form-data',
        }

        return new Promise((resolve, reject) => {
            axios.post(`${this.apiUrl}${path}`, data, { headers })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    post360(url: string, body) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${url}`, body)
                .then((res) => {
                    this.alertIfAdBlock()
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    sample(audienceId: number, sampleUsers = false) {
        const url = (sampleUsers === true) ? `${this.apiUrl}/audience/${audienceId}/sample/users` : `${this.apiUrl}/audience/${audienceId}/sample`

        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
}

const Api = new ApiService()
export default Api
