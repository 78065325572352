
import Vue from 'vue'
import { mapState } from 'vuex'
import { debounce } from 'lodash'
import { State } from '@/store'
import { replaceWord } from '@/filters/replaceWord.filter'
import { capitalize } from '@/filters/capitalize.filter'

function defaultDisplayValue(value: any) {
    return value
}

export default Vue.extend({
    name: 'SelectFiltersDropdown',
    filters: { replaceWord, capitalize },
    props: {
        col: null,
        label: String,
        activeFilters: {},
        filters: {},
        displayValue: {
            type: Function,
            default: defaultDisplayValue,
        },
    },
    computed: {
        ...mapState({
            loading: (state: State) => state.xhr.loading,
        }),
    },
    data() {
        return {
            distinctFilterValues: {},
            currentFilterSearchLoader: null,
        }
    },
    methods: {
        guessLabel(col: string) {
            return ['projectId', 'projectName', 'keyAccount'].includes(col)
                ? 'Project'
                : 'Target'
        },
        refreshFilters(col: string, term = '') {
            // reset loader and values first
            this.currentFilterSearchLoader = col

            const copy = this.distinctFilterValues
            copy[col] = [...[]]
            this.distinctFilterValues = { ...copy }

            this.$store
                .dispatch('proj/getFilterList', {
                    label: this.guessLabel(col),
                    limit: 15,
                    term,
                    field: col,
                })
                .then((distinctValuesList: any) => {
                    const copy = this.distinctFilterValues
                    copy[col] = [...distinctValuesList]
                    this.distinctFilterValues = { ...copy }

                    this.currentFilterSearchLoader = null
                })
                .catch(() => {
                    this.currentFilterSearchLoader = null
                })
        },
        onFilterDropdownFocus(col: string) {
            // plays when the dropdown show button (green caret) is clicked
            // load default values the first time the filter is opened
            if (typeof this.distinctFilterValues[col] === 'undefined') {
                this.$store
                    .dispatch('proj/getFilterList', {
                        label: this.guessLabel(col),
                        limit: 15,
                        term: '',
                        field: col,
                    })
                    .then((distinctValuesList: any) => {
                        const copy = this.distinctFilterValues
                        copy[col] = [...distinctValuesList]
                        this.distinctFilterValues = { ...copy }
                    })
                    .catch(() => {
                        // @fix
                    })
            }
        },
        onFilterSearch: debounce(function (e: any, col: string) {
            this.refreshFilters(col, e.target.value)
        }, 280),
        onFilterItem(itemId: any) {
            // if already selected, remove the machin
            if (this.isFilterItemSelected(itemId)) {
                this.$emit('removeActiveFilters', itemId)
                return
            }

            if (!this.activeFilters) {
                this.$emit('setActiveFilters', [itemId])
            } else {
                this.$emit('setActiveFilters', [...this.activeFilters, itemId])
            }
        },
        onResetFilters() {
            if (this.activeFilters) {
                this.$emit('removeActiveFilters')
            }
        },
        onResetFilterInput(col: string) {
            const input = this.$refs[`filterInputs-${col}`]
            input.value = ''
            this.refreshFilters(col, '')
        },
        isFilterItemSelected(item: any) {
            if (!this.activeFilters) {
                return false
            }

            if (this.activeFilters.includes(item)) {
                return true
            }
            return false
        },
        filtersAreFilteredByTerm(col: string) {
            const input = this.$refs[`filterInputs-${col}`]
            if (undefined === input) {
                return false
            }
            return input.value !== ''
        },
        orderBy(order: string) {
            this.$emit('orderBy', order)
        },
    },
})
